(function ($) {
	$.selectState = function (element, options) {
		// **************************************************************
		// Public Properties
		// **************************************************************

		var $c = $(element), // Context 

			SelectState = (function ($c, o) {
				var defaults = {
					'speed': 500,
					'easing': 'swing'
				}; 

				var _o = {};

				var _InitObjects = function () {
					_o = $.extend({}, defaults, o);
				},

					_SetCookie = function(s) {
						console.log('setting state cookie', s);
						$.cookie('SELECTED_STATE', s, { expires: 14.5, path: '/' });
						location.reload();
  					},


				_OnChange = function(e) {
					var $$ = $(this),
						s = $$.val().split('|')[1];
					_SetCookie(s);
				},

				_BindEvents = function () {
					$c.change(_OnChange);
				},

				init = (function() {
					_InitObjects();
					_BindEvents();
				})();
			}) ($c, options);
	};

	$.fn.selectState = function (options) {
		return this.each(function () {
			if (undefined === $(this).data('selectState')) {
				var plugin = new $.selectState(this, options);
				$(this).data('selectState', plugin);
			}
		});
	};
})(jQuery);
