(function ($) {
	$.loginRegister = function (element, options) {
		// **************************************************************
		// Public Properties
		// **************************************************************

		var $c = $(element), // Context 

			LoginRegister = (function ($c, o) {
				var defaults = {
					'speed': 500,
					'easing': 'swing'
				}; 

				var _o = {},

					$h = null, // Header
					$rb = null, // Register Button
					$lrm = null, // Login Register Modal
					$ov = null, // Overlay
					$mo = null, // Modal
					$close = null, // Close

					_state = '' 
				;

				var _InitObjects = function () {
					_o = $.extend({}, defaults, o);

					$rb = $('.register-button', $c);
					$lrm = $('.login-register-modal');

					$ov = $('.overlay', $lrm);
					$mo = $('.modal', $lrm);
					$close = $('.close-button', $lrm);
				},

					_ShowModal = function(d) {
						$lrm.fadeIn(_o.speed, _o.ease, function() {
							$close.one('click', _OnCloseClick);
							$ov.one('click', _OnOverlayClick);
						});
					},

					_CloseModal = function() {
						$lrm.fadeOut(_o.speed, _o.easing, function() {});
					},

				_OnCloseClick = function(e) {
					_CloseModal();
				},

				_OnOverlayClick = function(e) {
					_CloseModal();
				},

				_OnClick = function(e) {
					_ShowModal();
				},

				_BindEvents = function () {
					$rb.click(_OnClick);
				},

				init = (function() {
					_InitObjects();
					_BindEvents();

					$('#user_login').attr( 'placeholder', 'Username' );
					$('#user_pass').attr( 'placeholder', 'Password' );
				})();
			}) ($c, options);
	};

	$.fn.loginRegister = function (options) {
		return this.each(function () {
			if (undefined === $(this).data('loginRegister')) {
				var plugin = new $.loginRegister(this, options);
				$(this).data('loginRegister', plugin);
			}
		});
	};
})(jQuery);
