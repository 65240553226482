(function ($) {
	$.companySearch = function (element, options) {
		// **************************************************************
		// Public Properties
		// **************************************************************

		var $c = $(element), // Context 

			CompanySearch = (function ($c, o) {
				var defaults = {
					'speed': 500,
					'easing': 'swing'
				}; 

				var _o = {},

					$cont = null, // Container
					$sf = null, // Search Field
					$sb = null, // Search Button
					$ov = null, // Overlay
					$mo = null, // Modal
					$h = null, // Header
					$close = null, // Close

					_company = '' 
				;

				var _InitObjects = function () {
					_o = $.extend({}, defaults, o);

					$sf = $('input[type="text"]', $c);
					$sb = $('input[type="submit"]', $c);
				},

					_SearchCompanies = function(c) {
						_company = c;
						var url = '/wp-json/v1.0.0/company-search/' + encodeURI(_company);
						console.log(url);

						// Hit service and pass in the state
						$.ajax({
							method: "GET",
							url: url
						})
							.done(function( d ) {
								console.log( "Data Saved: ", d );
								_BuildResults(d);
							});
  					},

					_BuildResults = function(d) {
						if ($cont) {
							$cont.remove();
						}

						$cont = $('<div />')
							.addClass('modal-window')
							.addClass('company-search-results')
							.appendTo('body');

						_BuildOverlay();
						_BuildModal();
						_BuildClose();

						if (d.company) {
							_BuildFound(d.company);
						} else {
							_BuildNotFound(_company);
						}

						$cont.fadeIn(_o.speed, _o.ease, function() {});
					},

						_BuildOverlay = function() {
							$ov = $('<div />')
								.addClass('overlay')
								.appendTo($cont);
							$ov.click(_OnOverlayClick);
						},

						_BuildModal = function() {
							$mo = $('<div />')
								.addClass('modal')
								.appendTo($cont);
						},

						_BuildClose = function() {
							$close = $('<span />')
								.addClass('close-button')
								.html('X')
								.appendTo($mo);
							$close.click(_OnCloseClick);
						},

						_BuildFound = function(company) {
							$h = $('<h1 />')
								.addClass('title')
								.html(company + ' is part of The Employee Network!')
								.appendTo($mo);

							$('<p />')
								.addClass('message')
								.html('You are eligible for all the discounts listed on our site.')
								.appendTo($mo);

							$('<a />')
								.addClass('primary-button')
								.prop('href', '/wp-content/uploads/2017/05/employee-card.pdf')
								.prop('target', '_blank')
								.html('View Your Official Member Card')
								.click(_OnDownloadClick)
								.appendTo($mo);
						},

							_OnDownloadClick = function(e) {
								_CloseModal();
							},

						_BuildNotFound = function(search) {
							$h = $('<h1 />')
								.addClass('title')
								.html('"' + search + '"" is not part of The Employee Network')
								.appendTo($mo);

							$('<p />')
								.addClass('message')
								.html('Not a member? No problem! It\'s free and easy to join. Just have a manager or someone from HR visit our "Become a Corporate Partern" form!')
								.appendTo($mo);

							$('<a />')
								.addClass('primary-button')
								.prop('href', '#')
								.html('Become a Corporate Partner Today!')
								.appendTo($mo);
						},

				_OnCloseClick = function(e) {
					_CloseModal();
				},

				_OnOverlayClick = function(e) {
					_CloseModal();
				},

					_CloseModal = function() {
						$cont.fadeOut(_o.speed, _o.easing, function() {
							$cont.remove();
						});
					},
				
				_OnSubmitClick = function(e) {
					e.preventDefault();
					_SearchCompanies($sf.val());
				},

				_OnInputKeyUp = function(e) {
					if (e.which === 13) {
						$sb.click();
					}
				},

				_BindEvents = function () {
					$sb.click(_OnSubmitClick);
					$sf.keyup(_OnInputKeyUp);
				},

				init = (function() {
					_InitObjects();
					_BindEvents();
				})();
			}) ($c, options);
	};

	$.fn.companySearch = function (options) {
		return this.each(function () {
			if (undefined === $(this).data('companySearch')) {
				var plugin = new $.companySearch(this, options);
				$(this).data('companySearch', plugin);
			}
		});
	};
})(jQuery);
