(function ($) {
	$.mainNavigation = function (element, options) {
		// **************************************************************
		// Public Properties
		// **************************************************************

		var $c = $(element), // Context 

			MainNavigation = (function ($c, o) {
				var defaults = {
					'speed': 500,
					'easing': 'swing'
				}; 

				var _o = {}

					$s = null, // Sticky
					$ls = null, // Left Side
					$hb = null, // Hamburger
					$sbc = null // Search by Category
				;

				var _InitObjects = function () {
					_o = $.extend({}, defaults, o);

					$s = $('.sticky', $c);
					$hb = $('.menu-hamburger', $c);
					$ls = $('.page-left');
					$sbc = $('.tab', $ls);
				},

				_SetWayPoint = function() {
					var waypoint = new Waypoint({
						element: $s.get(0),
						handler: function() {
							$('body').toggleClass('scrolled');
						}
					});
				},

				_SetDimensions = function() {
					console.log('_SetDimensions', $s);
					var $da = $('.document-area');
					$s.outerWidth($da.width());
				},

				_OnResize = function(e) {
					_SetDimensions();
				},

				_OnHamburgerClick = function(e) {
					console.log('hamburger clicked');
					e.preventDefault();
					$c.toggleClass('open');
				},

				_OnCategorySearchClick = function(e) {
					console.log('search by category clicked');
					e.preventDefault();
					$ls.toggleClass('open');
				},

				_BindEvents = function () {
					$(window).resize(_OnResize);
					_SetWayPoint();

					$hb.click(_OnHamburgerClick);
					$sbc.click(_OnCategorySearchClick);
				},

				init = (function() {
					_InitObjects();
					_BindEvents();
					_SetDimensions();
				})();
			}) ($c, options);
	};

	$.fn.mainNavigation = function (options) {
		return this.each(function () {
			if (undefined === $(this).data('mainNavigation')) {
				var plugin = new $.mainNavigation(this, options);
				$(this).data('mainNavigation', plugin);
			}
		});
	};
})(jQuery);
