(function ($) {
	$.heroSlider = function (element, options) {
		// **************************************************************
		// Public Properties
		// **************************************************************

		var $c = $(element), // Context 

			HeroSlider = (function ($c, o) {
				var defaults = {
					'itemSelector': 'figure',
					'activeClass': 'active',

					'slideTime': 5000
				}; 

				var _o = {},
					$items = null,
					$nav = null,

					ci = 0, // Current Index
					clock = 0
				;

				var _InitObjects = function () {
					_o = $.extend({}, defaults, o);

					$items = $(_o.itemSelector, $c);

					_BuildNavigation();
				},

					_BuildNavigation = function() {
						if ($items.length <= 1) {
							return;
						}

						$nav = $('<nav />')
							.addClass('banner-navigation');

						$items.each(function(i) {
							var $item = $('<span />')
								.click(_OnNavItemClick);

							if (i == 0) {
								$item.addClass(_o.activeClass);
							}

							$nav.append($item);
						});

						$c.append($nav);
					},

				_StartClock = function() {
					clearInterval(clock);
					clock = setInterval(function() {
						_OnClock();
					}, _o.slideTime);
				},

				_OnNavItemClick = function(e) {
					e.preventDefault();
					
					clearInterval(clock);

					var $$ = $(this),
						ind = $$.index();
					console.log($$, ind);

					_SetCurrentSlide(ind);
					_StartClock();
				},

				_OnClock = function() {
					var ni = (ci + 1) % $items.length;

					_SetCurrentSlide(ni);
					_StartClock();
				},

					_SetCurrentSlide = function(index) {
						$items.removeClass(_o.activeClass);
						$items.eq(index).addClass(_o.activeClass);

						$nava.children()
							.removeClass(_o.activeClass)
							.eq(index).addClass(_o.activeClass);

						ci = index;
					},

				_BindEvents = function () {
					_StartClock();
				},

				init = (function() {
					_InitObjects();
					_BindEvents();
					_SetCurrentSlide(0);
				})();
			}) ($c, options);
	};

	$.fn.heroSlider = function (options) {
		return this.each(function () {
			if (undefined === $(this).data('heroSlider')) {
				var plugin = new $.heroSlider(this, options);
				$(this).data('heroSlider', plugin);
			}
		});
	};
})(jQuery);
