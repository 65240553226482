(function ($) {
	$.stateSearch = function (element, options) {
		// **************************************************************
		// Public Properties
		// **************************************************************

		var $c = $(element), // Context 

			StateSearch = (function ($c, o) {
				var defaults = {
					'speed': 500,
					'easing': 'swing'
				}; 

				var _o = {},

					$cont = null, // Container
					$sf = null, // Search Field
					$ov = null, // Overlay
					$mo = null, // Modal
					$h = null, // Header
					$close = null, // Close

					_state = '' 
				;

				var _InitObjects = function () {
					_o = $.extend({}, defaults, o);

					$sf = $('select', $c);
				},

					_SearchCompanies = function(s) {
						_state = s;
						var url = '/wp-json/v1.0.0/state-search/' + _state;

						// Hit service and pass in the state
						$.ajax({
							method: "GET",
							url: url
						})
							.done(function( d ) {
								console.log( "Data Saved: ", d );
								_BuildResults(d);
							});
  					},

					_BuildResults = function(d) {
						if ($cont) {
							$cont.remove();
						}

						$cont = $('<div />')
							.addClass('modal-window')
							.addClass('state-search-results')
							.appendTo('body');

						_BuildOverlay();
						_BuildModal();
						_BuildHeader(d.state);
						_BuildClose();
						_BuildStateList(d.clients);

						$cont.fadeIn(_o.speed, _o.ease, function() {});
					},

						_BuildOverlay = function() {
							$ov = $('<div />')
								.addClass('overlay')
								.appendTo($cont);
							$ov.click(_OnOverlayClick);
						},

						_BuildModal = function() {
							$mo = $('<div />')
								.addClass('modal')
								.appendTo($cont);
						},

						_BuildHeader = function(state) {
							$h = $('<h1 />')
								.addClass('title')
								.html(state + ' Corporate Partners')
								.appendTo($mo);
						},

						_BuildClose = function() {
							$close = $('<span />')
								.addClass('close-button')
								.html('X')
								.appendTo($mo);
							$close.click(_OnCloseClick);
						},

						_BuildStateList = function(clients) {
							var $sc = $('<div />')
									.addClass('client-list')
									.appendTo($mo);
							var lists = [];

							// Separate the data into 3 lists.


							for (var i = 0, l = clients.length; i < l; i++) {
								var $c = $('<p />')
									.addClass('client-item')
									.html(clients[i])
									.appendTo($sc);
							}
						},

				_OnCloseClick = function(e) {
					_CloseModal();
				},

				_OnOverlayClick = function(e) {
					_CloseModal();
				},

					_CloseModal = function() {
						$cont.fadeOut(_o.speed, _o.easing, function() {
							$cont.remove();
						});
					},
				
				_OnChange = function(e) {
					var $$ = $(this),
						s = $$.val().split('|')[0];
					_SearchCompanies(s);
				},

				_BindEvents = function () {
					$sf.change(_OnChange);
				},

				init = (function() {
					_InitObjects();
					_BindEvents();
				})();
			}) ($c, options);
	};

	$.fn.stateSearch = function (options) {
		return this.each(function () {
			if (undefined === $(this).data('stateSearch')) {
				var plugin = new $.stateSearch(this, options);
				$(this).data('stateSearch', plugin);
			}
		});
	};
})(jQuery);
